import React from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { SelectedFilters } from '@appbaseio/reactivesearch';
import { connect } from '@appbaseio/reactivesearch/lib/utils';
import { Sliders } from 'react-feather';
import MediaQuery from 'react-responsive';
import Spinner from './Spinner';
import SortOptions from './SortOptions';
import { MorphingRevealIconButton } from './Button';
import styles from './styles/SearchFilterBar.module.scss';

function ResultStats({ isLoading, total, hits }) {
  if (isLoading) {
    return <Spinner />;
  }
  if (hits && total) {
    return `${hits}/${total} items`;
  }
  return null;
}

const mapStateToProps = (state, props) => ({
  isLoading: state.isLoading['searchResults'] || false,
  total: state.hits['searchResults'] && state.hits['searchResults'].total,
  hits: state.hits['searchResults'] && state.hits['searchResults'].hits && state.hits['searchResults'].hits.length
});

const ConnectedResultStats = connect(
  mapStateToProps,
  null
)(props => <ResultStats {...props} />);

function SearchFilterBar({ isFiltersOpen, toggleFilters, currentSortOption, setSortOption }) {
  return (
    <Container fluid className={styles.container}>
      <Row className={styles.row} align="center" justify="between">
        <MediaQuery minWidth={400}>
          <Col s={6} md={4}>
            <SelectedFilters />
          </Col>
        </MediaQuery>
        <Col s={6} md={2}>
          <div className={styles.filterSortWrapper}>
            <div className={styles.statsWrapper}>
              <ConnectedResultStats />
            </div>
            <div className={styles.filterButtonWrapper}>
              <MorphingRevealIconButton isOpen={isFiltersOpen} onClick={toggleFilters} Icon={Sliders} />
            </div>
            <MediaQuery minWidth={400}>
              <div className={styles.sortWrapper}>
                <SortOptions currentSortOption={currentSortOption} setSortOption={setSortOption} />
              </div>
            </MediaQuery>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default SearchFilterBar;
