import React, { useState, Fragment } from 'react';
import Dropdown from 'react-bulma-components/lib/components/dropdown';
import Menu from 'react-bulma-components/lib/components/menu';
import { Menu as MenuIcon, MoreHorizontal, User, PlusCircle } from 'react-feather';
import MediaQuery from 'react-responsive';
import { Button } from './Button';
import DropDownMenu, { MenuItems } from './DropDownMenu';
import AddBookmarkDialog from './AddBookmarkDialog';
import styles from './styles/HeaderMenuButtons.module.scss';

import Auth from '../services/auth';
import history from '../services/history';
import config from '../services/config';

function noOp(label) {
  console.log(label);
}

const guestMenu = [{ label: 'Sign In', handler: Auth.signIn }];
const userMenu = [
  {
    label: 'Profile',
    handler: () => {
      history.push(config.routes.profile);
    }
  },
  { label: 'Sign Out', handler: Auth.signOut }
];
const moreMenu = ['About', 'Privacy Policy', 'Terms of Use'].map(item => ({
  label: item,
  handler: noOp.bind(null, item)
}));

function HeaderMenu({ user, closeHandler, openAddDialog }) {
  const userMenuItems = [{ label: 'Add Bookmark', handler: openAddDialog }, ...userMenu];

  return (
    <Menu className={styles.headerMenuFrame}>
      <Menu.List title="User">
        <MenuItems
          items={user.authenticated ? userMenuItems : guestMenu}
          ItemType={Menu.List.Item}
          closeHandler={closeHandler}
        />
      </Menu.List>
      <Menu.List title="Site">
        <MenuItems items={moreMenu} ItemType={Menu.List.Item} closeHandler={closeHandler} />
      </Menu.List>
    </Menu>
  );
}

function UserMenu({ user }) {
  const [showUserMenu, setUserMenu] = useState(false);

  if (user.authenticated)
    return (
      <DropDownMenu Icon={User} isOpen={showUserMenu} setMenuState={setUserMenu}>
        <MenuItems items={userMenu} ItemType={Dropdown.Item} closeHandler={() => setUserMenu(false)} />
      </DropDownMenu>
    );
  return (
    <Button onClick={() => Auth.signIn()} outlined rounded={false} color={null} className={styles.signInButton}>
      <div className={styles.signInLabel}>Sign In</div>
    </Button>
  );
}

function AddUrlButton({ user, openDialog }) {
  return (
    <Button onClick={openDialog}>
      <PlusCircle />
    </Button>
  );
}

function HeaderMenuButtons({ user }) {
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [showMoreMenu, setMoreMenu] = useState(false);
  const [showHeaderMenu, setHeaderMenu] = useState(false);
  const breakpoint = parseInt(styles.menuBreakpoint, 0);

  function openAddDialog() {
    setShowAddDialog(true);
  }

  function closeAddDialog() {
    setShowAddDialog(false);
  }

  return (
    <Fragment>
      <div className={styles.menuWrapper}>
        <MediaQuery maxWidth={breakpoint}>
          <DropDownMenu Icon={MenuIcon} isOpen={showHeaderMenu} setMenuState={setHeaderMenu}>
            <HeaderMenu user={user} closeHandler={() => setHeaderMenu(false)} openAddDialog={openAddDialog} />
          </DropDownMenu>
        </MediaQuery>
        <MediaQuery minWidth={breakpoint}>
          {user.authenticated && <AddUrlButton user={user} openDialog={openAddDialog} />}
          <UserMenu user={user} />
          <DropDownMenu Icon={MoreHorizontal} isOpen={showMoreMenu} setMenuState={setMoreMenu}>
            <MenuItems items={moreMenu} ItemType={Dropdown.Item} closeHandler={() => setMoreMenu(false)} />
          </DropDownMenu>
        </MediaQuery>
      </div>
      <AddBookmarkDialog isOpen={showAddDialog} user={user} closeHandler={closeAddDialog} />
    </Fragment>
  );
}
export default HeaderMenuButtons;
