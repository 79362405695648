import React, { useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { DataSearch } from '@appbaseio/reactivesearch';
import { X } from 'react-feather';
import MediaQuery from 'react-responsive';
import HeaderMenuButtons from './HeaderMenuButtons';
import QueryFormat from './QueryFormat';
import styles from './styles/SearchBar.module.scss';
import theme from '../theme.scss';
import config from '../services/config';

function Logo() {
  return (
    <div className={styles.logo}>
      <Link to={config.routes.home}>
        <MediaQuery maxWidth={600}>
          <img src="/junkdrawer-mark-blue.svg" alt="Junk Drawer" width="23" height="22" border="0" />
        </MediaQuery>
        <MediaQuery minWidth={600}>
          <img src="/junkdrawer-logo-blue-gray.svg" alt="Junk Drawer" width="180" height="22" border="0" />
        </MediaQuery>
      </Link>
    </div>
  );
}

function ClearIcon() {
  return (
    <div className={styles.clearSearch}>
      <X color={theme.colorIcon} />
    </div>
  );
}

function SearchInput({ handleNewSearch, queryFormat }) {
  const [query, setQuery] = useState('');

  function updateQuery(updatedQuery) {
    setQuery(updatedQuery);
  }
  function handleKeyPress(event) {
    if (event.key === 'Enter') {
      handleNewSearch(query);
    }
  }

  return (
    <div className={styles.searchInputContainer}>
      <DataSearch
        componentId="terms"
        dataField={['title', 'text', 'author', 'tags', 'publication.name']}
        fieldWeights={[6, 1, 4, 3, 3]}
        debounce={1000}
        showClear
        searchOperators
        clearIcon={<ClearIcon />}
        showIcon={false}
        queryFormat={queryFormat}
        placeholder="Search for an article, author, publication or tag."
        innerClass={{
          input: styles.searchInput
        }}
        autosuggest={false}
        iconPosition="left"
        filterLabel="search"
        URLParams
        onKeyPress={handleKeyPress}
        onValueChange={updateQuery}
      />
    </div>
  );
}

const SearchBar = ({ history, location, user, queryFormat, setQueryFormat }) => {
  function handleNewSearch(query) {
    if (location.pathname !== config.routes.home) {
      const path = `${config.routes.home}?terms="${encodeURIComponent(query)}"`;
      history.push(path);
    }
  }

  return (
    <div className={styles.header}>
      <Logo />
      <SearchInput handleNewSearch={handleNewSearch} queryFormat={queryFormat} />
      <MediaQuery minWidth={400}>
        <QueryFormat format={queryFormat} setQueryFormat={setQueryFormat} />
      </MediaQuery>
      <HeaderMenuButtons user={user} />
    </div>
  );
};

export default withRouter(SearchBar);
