import React, { useState } from 'react';
import Dropdown from 'react-bulma-components/lib/components/dropdown';
import Tag from 'react-bulma-components/lib/components/tag';
import urlParse from 'url-parse';
import { Bookmark, Calendar, Clock, MoreHorizontal } from 'react-feather';
import { HiStar, HiOutlineStar, HiCode, HiColorSwatch } from 'react-icons/hi'; // BsFileText, HiCode, VscLibrary, GrTool
import { BsFileText } from 'react-icons/bs';
import { VscLibrary } from 'react-icons/vsc';
import { GrTools } from 'react-icons/gr';

import moment from 'moment';

import classnames from 'classnames';
import styles from './styles/SearchResult.module.scss';
import theme from '../theme.scss';
import DropDownMenu, { MenuItems } from './DropDownMenu';
import AnimatedVisibility from './AnimatedVisibility';
import { IconButton } from './Button';

const momentFormats = {
  sameDay: '[Today]',
  nextDay: '[Tomorrow]',
  nextWeek: 'dddd',
  lastDay: '[Yesterday]',
  lastWeek: '[Last] ddd',
  sameElse: 'MMM D, YYYY'
};

const sourceIcons = {
  pocket: 'fa-get-pocket',
  medium: 'fa-medium'
};

function buildImageUrl(previewImageId) {
  if (previewImageId && previewImageId.length > 0)
    return previewImageId.startsWith('http')
      ? previewImageId
      : `https://cdn-images-1.medium.com/max/250/${previewImageId}`;
  return '/browser.svg';
}

function NewWindowLink({ url, color, children }) {
  return (
    <a rel="noopener noreferrer" target="_blank" className={styles.link} href={url} style={{ color: color }}>
      {children}
    </a>
  );
}

function Publication({ item }) {
  const publicationName =
    item.publication.name && !item.publication.name.startsWith('http')
      ? item.publication.name
      : urlParse(item.publication.url).hostname;
  const hasSourceIcon = Boolean(sourceIcons[item.source]);
  return (
    <div className={styles.publication}>
      <NewWindowLink url={item.publication.url} color={theme.colorText}>
        {hasSourceIcon && <i className={classnames('fab', sourceIcons[item.source], styles.sourceIcon)} />}
        {publicationName}
      </NewWindowLink>
    </div>
  );
}

function Tags({ item }) {
  return (
    <div className={styles.tags}>
      <Tag.Group>
        {item.tags.map((t) => (
          <Tag key={t}>{t}</Tag>
        ))}
      </Tag.Group>
    </div>
  );
}

function ArticleLink({ item, children }) {
  return <NewWindowLink url={item.url}>{children}</NewWindowLink>;
}

function MoreMenu({ openDeleteDialog, openTagEditor, bookmark, onDeleteCallback }) {
  const [showMoreMenu, setMoreMenu] = React.useState(false);
  const moreMenu = [
    { label: 'Delete', handler: () => openDeleteDialog(bookmark, onDeleteCallback) },
    { label: 'Edit', handler: () => openTagEditor(bookmark) }
  ];
  return (
    <DropDownMenu openUp Icon={MoreHorizontal} isOpen={showMoreMenu} setMenuState={setMoreMenu}>
      <MenuItems items={moreMenu} ItemType={Dropdown.Item} closeHandler={() => setMoreMenu(false)} />
    </DropDownMenu>
  );
}

function TypeIcons({ bookmark }) {
  const mapping = {
    article: BsFileText,
    library: HiCode,
    publication: VscLibrary,
    tool: GrTools,
    catalog: HiColorSwatch
  };
  return (
    <div className={styles.metadata}>
      {bookmark.types.map((t) => {
        const Icon = mapping[t];
        if (t) return <Icon key={t} className={styles.icon} title={t} />;
        return null;
      })}
    </div>
  );
}

function SearchResult({ item, openDeleteDialog, openTagEditor, includeMoreMenu, toggleRating }) {
  const [visible, setVisible] = useState(true);
  const [isStarred, setStarred] = useState(Boolean(item.rating > 0));

  function hideResult() {
    setVisible(false);
  }

  const onStarredClick = () => {
    toggleRating(item, !isStarred);
    setStarred(!isStarred);
  };

  return (
    <AnimatedVisibility isVisible={visible}>
      <div className={styles.card}>
        <div className={styles.contentBackground}>
          <div className={styles.grid}>
            <ArticleLink item={item}>
              <div className={styles.image} style={{ backgroundImage: `url(${buildImageUrl(item.previewImageId)})` }} />
            </ArticleLink>
            <ArticleLink item={item}>
              <div className={styles.title}>{item.title}</div>
            </ArticleLink>
            <Publication item={item} />
            <Tags item={item} />
            <div className={styles.metadata}>
              <Clock className={styles.icon} title="readingTime" />
              {`${Math.round(item.readingTime)} min`}
            </div>
            <div className={styles.metadata}>
              <Calendar className={styles.icon} title="publishedAt" />
              {moment(item.publishedAt).calendar(null, momentFormats)}
            </div>
            <div className={styles.metadata}>
              <Bookmark className={styles.icon} title="bookmarkedAt" />
              {moment(item.bookmarkedAt).fromNow(true)}
            </div>
            <div className={styles.more}>
              <TypeIcons bookmark={item} />
              <div>
                {includeMoreMenu && <IconButton Icon={isStarred ? HiStar : HiOutlineStar} onClick={onStarredClick} />}
                {includeMoreMenu && (
                  <MoreMenu
                    openDeleteDialog={openDeleteDialog}
                    openTagEditor={openTagEditor}
                    bookmark={item}
                    onDeleteCallback={hideResult}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </AnimatedVisibility>
  );
}
export default SearchResult;
