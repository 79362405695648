import React from 'react';
import { ReactiveBase } from '@appbaseio/reactivesearch';
import SearchBar from './SearchBar';
import config from '../services/config';
import ApiClient from '../services/api-client';
import { HeaderBar } from './Containers';
import styles from './styles/AppContainer.module.scss';

function AppContainer({ user, children, queryFormat, setQueryFormat }) {
  function signRequests(request) {
    return ApiClient.signAwsRequest(request, `${user.reactiveSearchApp}/_msearch`, user.credentials, config);
  }

  return (
    <ReactiveBase app={user.reactiveSearchApp} url={config.apiBaseUrl} transformRequest={signRequests}>
      <main className={styles.frame}>
        <HeaderBar>
          <SearchBar user={user} queryFormat={queryFormat} setQueryFormat={setQueryFormat} />
        </HeaderBar>
        {children}
      </main>
    </ReactiveBase>
  );
}

export default AppContainer;
