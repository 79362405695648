import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import ProfileScene from './components/ProfileScene';
import SearchScene from './components/SearchScene';
import { SignIn, RedirectHome, SignOut, SignInCallback, AuthenticatedRoute } from './components/Auth';
import config from './services/config';

function Routes({ user, history }) {
  return (
    <Router history={history}>
      <Switch>
        <AuthenticatedRoute user={user} path={config.routes.profile} render={() => <ProfileScene user={user} />} />
        <Route path={config.routes.signIn} component={SignIn} />
        <Route path={config.routes.signOut} component={SignOut} />
        <Route path={config.routes.authenticatedCallback} render={() => <SignInCallback user={user} />} />
        <Route path={config.routes.signedOutCallback} component={RedirectHome} />
        <Route path={config.routes.home} render={() => <SearchScene user={user} />} />
      </Switch>
    </Router>
  );
}

export default Routes;
