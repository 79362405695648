import React from 'react';
import Dropdown from '@appbaseio/reactivesearch/lib/components/shared/Dropdown';
import styles from './styles/SortOptions.module.scss';

export const sortOptions = [
  {
    fields: [{ _score: 'desc' }],
    label: 'Relevance'
  },
  {
    fields: [{ bookmarkedAt: 'desc' }],
    label: 'Bookmarked At (newest)'
  },
  {
    fields: [{ rating: 'desc' }, { _score: 'desc' }],
    label: 'Starred, Relevance'
  },
  {
    fields: [{ rating: 'desc' }, { bookmarkedAt: 'desc' }],
    label: 'Starred, Bookmarked At (newest)'
  },
  {
    fields: [{ publishedAt: 'desc' }],
    label: 'Published At (newest)'
  },
  {
    fields: [{ bookmarkedAt: 'asc' }],
    label: 'Bookmarked At (oldest)'
  },
  {
    fields: [{ publishedAt: 'asc' }],
    label: 'Published At (oldest)'
  }
];

const optionsForDropdown = sortOptions.map((opt) => ({ key: opt.label, count: 1 }));

function SortOptions({ currentSortOption, setSortOption }) {
  function onSortChange(selection) {
    setSortOption(sortOptions.find((item) => item.label === selection));
  }
  return (
    <Dropdown
      items={optionsForDropdown}
      innerClass={{ select: styles.select }}
      onChange={onSortChange}
      selectedItem={currentSortOption.label}
      labelField="key"
      showCount={false}
      showSearch={false}
    />
  );
}

export default SortOptions;
