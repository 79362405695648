import React, { useState } from 'react';
import PropTypes from 'prop-types';
import BulmaButton from 'react-bulma-components/lib/components/button';
import { X } from 'react-feather';
import MorphReplace from '../external/react-svg-morph/MorphReplace';
import styles from './styles/Button.module.scss';
import theme from '../theme.scss';

export function Button({ children, onClick, color, rounded, outlined, className, ...rest }) {
  return (
    <BulmaButton
      color={color}
      rounded={rounded}
      outlined={outlined}
      onClick={onClick}
      className={className ? className : styles.button}
      {...rest}
    >
      <div className={styles.buttonIcon}>{children}</div>
    </BulmaButton>
  );
}

Button.defaultProps = {
  rounded: true,
  outlined: false,
  color: 'white'
};

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  onClick: PropTypes.func.isRequired,
  color: PropTypes.string,
  rounded: PropTypes.bool,
  outlined: PropTypes.bool
};

export function RevealButton({ children, isOpen, ...rest }) {
  return <Button {...rest}>{isOpen ? <X /> : children}</Button>;
}

RevealButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  isOpen: PropTypes.bool.isRequired
};

export function HoverIconButton({ setIconColor, color, hoverColor, children, ...buttonProps }) {
  function onMouseEnter() {
    setIconColor(hoverColor);
  }
  function onMouseLeave() {
    setIconColor(color);
  }

  return (
    <Button {...buttonProps} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {children}
    </Button>
  );
}

HoverIconButton.defaultProps = {
  color: theme.colorIcon,
  hoverColor: theme.colorPrimary
};

HoverIconButton.propTypes = {
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  setIconColor: PropTypes.func.isRequired
};

export function IconButton({ Icon, height, width, color, hoverColor, ...buttonProps }) {
  const [iconColor, setIconColor] = useState(color);

  return (
    <HoverIconButton setIconColor={setIconColor} color={color} hoverColor={hoverColor} {...buttonProps}>
      <Icon color={iconColor} style={{ width, height }} />
    </HoverIconButton>
  );
}

IconButton.defaultProps = {
  width: 24,
  height: 24,
  color: theme.colorIcon,
  hoverColor: theme.colorPrimary
};

IconButton.propTypes = {
  Icon: PropTypes.func.isRequired,
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

export function MorphingRevealIconButton({ Icon, height, width, color, hoverColor, isOpen, ...buttonProps }) {
  const [iconColor, setIconColor] = useState(color);

  return (
    <HoverIconButton {...buttonProps} setIconColor={setIconColor} color={color} hoverColor={hoverColor}>
      <MorphReplace width={height} height={width} rotation="none" preserveAspectRatio="none">
        {isOpen ? <X key="x-close" color={hoverColor} /> : <Icon key={`icon-${iconColor}`} color={iconColor} />}
      </MorphReplace>
    </HoverIconButton>
  );
}

MorphingRevealIconButton.defaultProps = {
  width: 24,
  height: 24,
  color: theme.colorIcon,
  hoverColor: theme.colorPrimary
};

MorphingRevealIconButton.propTypes = {
  Icon: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};
