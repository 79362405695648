import React, { Fragment, useState } from 'react';
import SearchFilterBar from './SearchFilterBar';
import SearchFilters from './SearchFilters';
import SearchResults from './SearchResults';
import { HeaderBar, MainSection } from './Containers';
import AppContainer from './AppContainer';
import 'react-toastify/dist/ReactToastify.css';
import ToastContainer from './ToastContainer';
import { sortOptions } from './SortOptions';

function SearchScene({ user }) {
  const [showFilters, setShowFilters] = useState(false);
  const [sortOption, setSortOption] = useState(sortOptions[1]);

  const [queryFormat, setQueryFormat] = useState('or');

  function toggleFilters() {
    setShowFilters(!showFilters);
  }

  return (
    <Fragment>
      <AppContainer user={user} queryFormat={queryFormat} setQueryFormat={setQueryFormat}>
        <HeaderBar>
          <SearchFilterBar
            isFiltersOpen={showFilters}
            toggleFilters={toggleFilters}
            currentSortOption={sortOption}
            setSortOption={setSortOption}
          />
        </HeaderBar>
        <HeaderBar>
          <SearchFilters
            showFilters={showFilters}
            currentSortOption={sortOption}
            setSortOption={setSortOption}
            queryFormat={queryFormat}
            setQueryFormat={setQueryFormat}
          />
        </HeaderBar>

        <MainSection id="search-results">
          <SearchResults sortOption={sortOption} user={user} />
        </MainSection>
        <ToastContainer />
      </AppContainer>
    </Fragment>
  );
}

export default SearchScene;
