import React from 'react';
import Button from 'react-bulma-components/lib/components/button';
import styles from './styles/QueryFormat.module.scss';

function QueryFormatButton({ isSelected, text, format, updateFormat }) {
  return (
    <Button
      isSelected={isSelected}
      rounded
      size="small"
      color={isSelected ? 'primary' : ''}
      onClick={() => updateFormat(format)}
    >
      {text}
    </Button>
  );
}

export default function QueryFormat({ format, setQueryFormat }) {
  return (
    <div className={styles.wrapper}>
      <Button.Group hasAddons>
        <QueryFormatButton format="and" text="all" updateFormat={setQueryFormat} isSelected={format === 'and'} />
        <QueryFormatButton format="or" text="any" updateFormat={setQueryFormat} isSelected={format === 'or'} />
      </Button.Group>
    </div>
  );
}
