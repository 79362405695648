import React, { Fragment, useState } from 'react';
import { ReactiveList } from '@appbaseio/reactivesearch';
import SearchResult from './SearchResult';
import DeleteDialog from './DeleteDialog';
import BookmarkEditorDialog from './BookmarkEditorDialog';
import config from '../services/config';
import apiClient from '../services/api-client';
import styles from './styles/SearchResults.module.scss';

function SearchResults({ user, sortOption }) {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showTagEditor, setShowTagEditor] = useState(false);
  const [dialogProps, setDialogProps] = useState();

  function closeDeleteDialog() {
    setShowDeleteDialog(false);
  }

  function openDeleteDialog(bookmark, onDeleteCallback) {
    setDialogProps({ bookmark, onDeleteCallback });
    setShowDeleteDialog(true);
  }

  function closeTagEditor() {
    setShowTagEditor(false);
  }

  function openTagEditor(bookmark, onDoneCallback) {
    setDialogProps({ bookmark, onDoneCallback });
    setShowTagEditor(true);
  }

  async function toggleRating(item, isStarred) {
    const resp = await apiClient.updateStarred(item._id, isStarred, user.credentials, config);
    console.log('toggleRating', resp);
  }

  return (
    <Fragment>
      <DeleteDialog isOpen={showDeleteDialog} closeHandler={closeDeleteDialog} user={user} {...dialogProps} />
      <BookmarkEditorDialog isOpen={showTagEditor} closeHandler={closeTagEditor} user={user} {...dialogProps} />
      <ReactiveList
        componentId="searchResults"
        dataField="bookmarkedAt"
        innerClass={{ sortOptions: styles.sortOptions }}
        react={{
          and: ['terms', 'types', 'publication', 'tags', 'bookmarkedAt', 'publishedAt', 'source']
        }}
        scrollTarget="search-results"
        infiniteScroll
        defaultQuery={() => ({
          sort: sortOption.fields
        })}
        showResultStats={false}
        // excludeFields={["text"]}
        render={(results) => (
          <ReactiveList.ResultCardsWrapper>
            {results.data.map((item) => (
              <SearchResult
                key={item._id}
                item={item}
                openDeleteDialog={openDeleteDialog}
                openTagEditor={openTagEditor}
                includeMoreMenu={user.authenticated}
                toggleRating={toggleRating}
              />
            ))}
          </ReactiveList.ResultCardsWrapper>
        )}
      />
    </Fragment>
  );
}

export default SearchResults;
