import aws4 from 'aws4';
import axios from 'axios';

const ApiClient = {
  signAwsRequest(request, path, credentials, config) {
    if (!credentials) {
      console.log('No credentials found for request signing!');
      return request;
    }

    const { accessKeyId, secretAccessKey, sessionToken } = credentials;
    const opts = {
      method: request.method,
      service: 'execute-api',
      region: config.awsConfig.Auth.region,
      path: `/${path}`,
      host: config.apiHost,
      headers: request.headers,
      url: `https://${config.apiHost}/${path}`
    };
    if (request.method === 'POST') {
      opts.body = request.body;
      if (request.data) opts.data = request.data;
    }
    const signedRequest = aws4.sign(opts, {
      accessKeyId,
      secretAccessKey,
      sessionToken
    });
    delete signedRequest.headers.Host;
    delete signedRequest.headers['Content-Length'];
    return signedRequest;
  },

  deleteBookmark(id, credentials, config) {
    const data = { id };
    const request = {
      method: 'POST',
      body: JSON.stringify(data),
      data,
      headers: { 'Content-Type': 'application/json', Accept: 'application/json' }
    };
    const signedRequest = this.signAwsRequest(request, 'users/me/bookmarks/delete', credentials, config);
    return axios(signedRequest);
  },

  async addBookmark(url, tags, types, webhookUrl) {
    const resp = await axios.post(webhookUrl, { url, tags: tags.join(','), types: types.join(',') });
    return resp.data.message;
  },

  async fetchTags(credentials, config) {
    // If headers are added to the get request, the AWS4 signature is invalid
    // no headers and it works. I have no idea why.

    const request = {
      method: 'GET'
    };
    const signedRequest = this.signAwsRequest(request, 'users/me/tags', credentials, config);
    const response = await axios(signedRequest);
    const tags = response.data.filter(t => t.name.trim().length > 0);
    tags.sort((a, b) => a.name.localeCompare(b.name, { sensitivity: 'base' }));
    return tags;
  },

  async fetchBookmarksForExport(credentials, config) {
    // If headers are added to the get request, the AWS4 signature is invalid
    // no headers and it works. I have no idea why.

    const request = {
      method: 'GET'
    };
    const signedRequest = this.signAwsRequest(request, 'users/me/bookmarks/actions.export', credentials, config);
    const response = await axios(signedRequest);
    return response.data;
  },

  async updateBookmark(id, data, credentials, config) {
    const request = {
      method: 'POST',
      body: JSON.stringify(data),
      data,
      headers: { 'Content-Type': 'application/json', Accept: 'application/json' }
    };
    const signedRequest = this.signAwsRequest(request, `users/me/bookmarks/${id}/update`, credentials, config);
    return axios(signedRequest);
  },

  async updateStarred(id, isStarred, credentials, config) {
    const data = { rating: isStarred ? 5 : 0 };
    return this.updateBookmark(id, data, credentials, config);
  }
};

export default ApiClient;
