import React from 'react';
import styles from './styles/Containers.module.scss';

export function MainSection({ children, ...rest }) {
  return (
    <section className={styles.flexibleContent} {...rest}>
      {children}
    </section>
  );
}

export function HeaderBar({ children, ...rest }) {
  return (
    <header className={styles.fixedPosition} {...rest}>
      {children}
    </header>
  );
}
