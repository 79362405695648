import React from 'react';
import Button from 'react-bulma-components/lib/components/button';
import Modal from 'react-bulma-components/lib/components/modal';
import Content from 'react-bulma-components/lib/components/content';
import styles from './styles/ActionDialog.module.scss';

function ActionDialog({ isOpen, closeHandler, actionHandler, actionLabel, title, children }) {
  async function confirmationHandler() {
    const success = await actionHandler();
    if (success) closeHandler();
  }

  return (
    <Modal show={isOpen} onClose={closeHandler} closeOnBlur>
      <Modal.Card className={styles.dialog}>
        <Modal.Card.Body>
          <Content>
            <h4 className={styles.message}>{title}</h4>
            {children}
          </Content>
          <div>
            <Button onClick={confirmationHandler} color="primary" className={styles.button}>
              {actionLabel}
            </Button>
            <Button onClick={closeHandler} outlined className={styles.button}>
              Cancel
            </Button>
          </div>
        </Modal.Card.Body>
      </Modal.Card>
    </Modal>
  );
}
export default ActionDialog;
