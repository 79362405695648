import React, { useState } from 'react';
import { Control, Field, Input, Label, Help } from 'react-bulma-components/lib/components/form';
import { toast } from 'react-toastify';

import config, { webhookUrlForUser } from '../services/config';
import apiClient from '../services/api-client';
import ActionDialog from './ActionDialog';
import MultiValueEditor from './MultiValueEditor';
import styles from './styles/AddBookmarkDialog.module.scss';

function AddBookmarkDialog({ user, isOpen, closeHandler }) {
  const [url, setUrl] = useState('');
  const [isValid, setValid] = useState(true);
  const [tags, setTags] = useState(null);
  const [types, setTypes] = useState(['article']);

  function handleChange(event) {
    setUrl(event.target.value);
  }

  async function addBookmark() {
    if (!url.startsWith('https') && !url.startsWith('http')) {
      setValid(false);
      return false;
    }

    setValid(true);
    const resp = await apiClient.addBookmark(url, tags, types, webhookUrlForUser(user));
    toast.success('Added bookmark', { position: toast.POSITION.BOTTOM_LEFT });
    console.log('added bookmark', resp);
    setUrl('');
    setTags(null);
    setTypes(null);
    return true;
  }

  function onTagsChange(selected) {
    setTags(selected);
  }

  function onTypesChange(selected) {
    setTypes(selected);
  }

  async function fetchTypes() {
    return ['article', 'library', 'publication', 'tool', 'catalog'];
  }
  async function fetchTags() {
    const existingTags = await apiClient.fetchTags(user.credentials, config);
    return existingTags ? existingTags.map((t) => t.name) : null;
  }

  return (
    <ActionDialog
      isOpen={isOpen}
      closeHandler={closeHandler}
      actionHandler={addBookmark}
      title="Add Bookmark"
      actionLabel="Add"
    >
      <Field>
        <Label>URL</Label>
        <Control className={styles.form}>
          <Input
            className={styles.url}
            color={isValid ? 'primary' : 'danger'}
            name="url"
            placeholder="https://..."
            value={url}
            onChange={handleChange}
          />
        </Control>
        {!isValid && <Help color="danger">Invalid url.</Help>}
      </Field>
      <div className={styles.tags}>
        <Label>Tags</Label>
        <MultiValueEditor onChange={onTagsChange} values={tags} fetchOptions={fetchTags} />
      </div>
      <div className={styles.tags}>
        <Label>Types</Label>
        <MultiValueEditor onChange={onTypesChange} values={types} fetchOptions={fetchTypes} />
      </div>
    </ActionDialog>
  );
}
export default AddBookmarkDialog;
