import React, { useState } from 'react';
import { Label } from 'react-bulma-components/lib/components/form';
import { toast } from 'react-toastify';
import config from '../services/config';
import apiClient from '../services/api-client';
import ActionDialog from './ActionDialog';
import MultiValueEditor from './MultiValueEditor';
import styles from './styles/BookmarkEditorDialog.module.scss';

function BookmarkEditorDialog({ user, bookmark, isOpen, closeHandler, onDoneCallback }) {
  const [updatedTags, setTags] = useState(null);
  const [updatedTypes, setTypes] = useState(null);

  async function updateBookmark() {
    try {
      await apiClient.updateBookmark(
        bookmark._id,
        { tags: updatedTags, types: updatedTypes },
        user.credentials,
        config
      );
      toast.success('Updated Bookmark');

      if (onDoneCallback) onDoneCallback();
      return true;
    } catch (ex) {
      toast.error(ex.message);
      return false;
    }
  }

  function onTagsChange(selected) {
    setTags(selected);
  }

  async function fetchTags() {
    const existingTags = await apiClient.fetchTags(user.credentials, config);
    return existingTags ? existingTags.map((t) => t.name) : null;
  }

  function onTypesChange(selected) {
    setTypes(selected);
  }

  async function fetchTypes() {
    return ['article', 'library', 'publication', 'tool', 'catalog'];
  }

  const title = 'Edit Tags';
  return (
    <ActionDialog
      isOpen={isOpen}
      closeHandler={closeHandler}
      actionHandler={updateBookmark}
      title={title}
      actionLabel="Update"
    >
      <h6>{bookmark && bookmark.title}</h6>
      <p>{bookmark && bookmark.url}</p>
      <div className={styles.tags}>
        <Label>Tags</Label>
        <MultiValueEditor onChange={onTagsChange} values={bookmark ? bookmark.tags : null} fetchOptions={fetchTags} />
      </div>
      <div className={styles.tags}>
        <Label>Types</Label>
        <MultiValueEditor
          onChange={onTypesChange}
          values={bookmark ? bookmark.types : null}
          fetchOptions={fetchTypes}
        />
      </div>
    </ActionDialog>
  );
}
export default BookmarkEditorDialog;
