import React from 'react';
import Avatar from 'react-avatar';

function UserAvatar({ user, ...rest }) {
  return (
    <Avatar
      email={user.attributes.email}
      name={`${user.attributes.given_name} ${user.attributes.family_name}`}
      {...rest}
    />
  );
}

export default UserAvatar;
