import React from 'react';
import PropTypes from 'prop-types';
import { MultiDropdownRange, MultiDropdownList, SelectedFilters } from '@appbaseio/reactivesearch';
import { Col, Container, Row } from 'react-grid-system';
import { Collapse } from 'react-collapse';
import MediaQuery from 'react-responsive';
import Title from '@appbaseio/reactivesearch/lib/styles/Title';
import SortOptions from './SortOptions';
import QueryFormat from './QueryFormat';
import styles from './styles/SearchFilters.module.scss';

function ColumnWrappedFilter({ children }) {
  return (
    <Col xs={6} sm={3} lg={1} className={styles.col}>
      {children}
    </Col>
  );
}

function RangeFilter({ title, field, id, data }) {
  return (
    <MultiDropdownRange
      className={styles.selectWrapper}
      componentId={id}
      dataField={field}
      title={title}
      data={data}
      innerClass={{ select: styles.selectBox }}
      URLParams
    />
  );
}

function ReadingTimeFilter() {
  return (
    <RangeFilter
      id="readingTime"
      field="readingTime"
      title="Reading Time"
      data={[
        { start: 0, end: 5, label: '<5 min' },
        { start: 5, end: 10, label: '5 - 10 min' },
        { start: 10, end: 1000, label: '> 10 min' }
      ]}
      URLParams
    />
  );
}

function AgeFilter({ field, title }) {
  const ageOptions = [
    { start: 'now-7d', end: 'now', label: '< 1 week' },
    { start: 'now-1M', end: 'now-7d', label: '1 week to 1 month' },
    { start: 'now-6M', end: 'now-1M', label: '1 month to 6 months' },
    { start: 'now-1y', end: 'now-6M', label: '6 months to 1 year' },
    { start: 'now-10y', end: 'now-1y', label: '> 1 year' }
  ];
  return <RangeFilter id={field} field={field} title={title} data={ageOptions} />;
}

const reactFilterIds = ['terms', 'types', 'publication', 'bookmarkedAt', 'publishedAt', 'source', 'tags'];

function MultiDataFilter({ componentId, dataField, title, showSearch, queryFormat = 'or' }) {
  const react = queryFormat === 'or' ? reactFilterIds.filter((item) => item !== componentId) : reactFilterIds;
  return (
    <MultiDropdownList
      className={styles.selectWrapper}
      innerClass={{ select: styles.selectBox }}
      componentId={componentId}
      dataField={dataField}
      queryFormat={queryFormat}
      title={title}
      showSearch={showSearch}
      react={{
        and: react
      }}
      size={300}
      URLParams
      loader="Loading ..."
    />
  );
}
MultiDataFilter.defaultProps = {
  showSearch: true
};
MultiDataFilter.propTypes = {
  componentId: PropTypes.string.isRequired,
  dataField: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  showSearch: PropTypes.bool
};

function SourceFilter() {
  return <MultiDataFilter componentId="source" dataField="source" title="Source" showSearch={false} />;
}

function PublicationFilter() {
  return <MultiDataFilter componentId="publication" dataField="publication.name.raw" title="Publication" />;
}

function TagsFilter({ queryFormat }) {
  return <MultiDataFilter componentId="tags" dataField="tags.raw" title="Tags" queryFormat={queryFormat} />;
}

function TypesFilter() {
  return <MultiDataFilter componentId="types" dataField="types.raw" title="Types" />;
}

// 'Bookmark Age', 'Published', 'Publication', 'Tags'

function SearchFilters({ currentSortOption, setSortOption, queryFormat, setQueryFormat }) {
  return (
    <Container fluid>
      <Row className={styles.row}>
        <MediaQuery maxWidth={400}>
          <ColumnWrappedFilter>
            <SelectedFilters />
          </ColumnWrappedFilter>
          <ColumnWrappedFilter>
            <div className={styles.queryFormatContainer}>
              <Title className={styles.queryFormatLabel}>Query Terms</Title>
              <QueryFormat format={queryFormat} updateFormat={setQueryFormat} />
            </div>
          </ColumnWrappedFilter>
          <ColumnWrappedFilter>
            <Title>Sort Options</Title>
            <SortOptions currentSortOption={currentSortOption} setSortOption={setSortOption} />
          </ColumnWrappedFilter>
        </MediaQuery>
        <ColumnWrappedFilter>
          <TagsFilter queryFormat={queryFormat} />
        </ColumnWrappedFilter>
        <ColumnWrappedFilter>
          <SourceFilter />
        </ColumnWrappedFilter>
        <ColumnWrappedFilter>
          <TypesFilter />
        </ColumnWrappedFilter>
        <ColumnWrappedFilter>
          <AgeFilter field="bookmarkedAt" title="Bookmark Age" />
        </ColumnWrappedFilter>
        <ColumnWrappedFilter>
          <AgeFilter field="publishedAt" title="Published Age" />
        </ColumnWrappedFilter>
        <ColumnWrappedFilter>
          <PublicationFilter />
        </ColumnWrappedFilter>
      </Row>
    </Container>
  );
}

function SearchFiltersAnimationWrapper({ showFilters, ...props }) {
  return (
    <Collapse isOpened={showFilters}>
      <SearchFilters {...props} key="searchFilters" />
    </Collapse>
  );
}

export default SearchFiltersAnimationWrapper;
