import React from 'react';
import { toast } from 'react-toastify';
import config from '../services/config';
import apiClient from '../services/api-client';
import ActionDialog from './ActionDialog';

function DeleteDialog({ user, bookmark, isOpen, closeHandler, onDeleteCallback }) {
  async function deleteBookmark() {
    const resp = await apiClient.deleteBookmark(bookmark._id, user.credentials, config);
    toast.success('Deleted bookmark');

    console.log('deleted bookmark:', resp);
    if (onDeleteCallback) onDeleteCallback();
    return true;
  }
  const title = 'Are you sure you want to delete this bookmark?';
  return (
    <ActionDialog
      isOpen={isOpen}
      closeHandler={closeHandler}
      actionHandler={deleteBookmark}
      title={title}
      actionLabel="Delete"
    >
      <h6>{bookmark && bookmark.title}</h6>
      <p>{bookmark && bookmark.url}</p>
    </ActionDialog>
  );
}
export default DeleteDialog;
