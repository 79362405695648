import React from 'react';
import classnames from 'classnames';
import styles from './styles/Spinner.module.scss';

// Based on https://webkul.github.io/csspin/

export function FullPageSpinner() {
  return (
    <div className={styles.fullPageContainer}>
      <div className={styles.fullPageCenterScreen}>
        <div className={classnames(styles.fullPageSpinner, styles.fullPageSpinnerContent)} />
      </div>
    </div>
  );
}

function Spinner({style}) {
  return <div className={classnames(styles.smallSpinner, styles.smallSpinnerContent)} style={style} />;
}
export default Spinner;
