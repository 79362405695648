import React from 'react';
import { Slide, ToastContainer as Container } from 'react-toastify';
import { XCircle } from 'react-feather';
import 'react-toastify/dist/ReactToastify.css';

import styles from './styles/ToastContainer.module.scss';

function CloseToast({ closeToast }) {
  return <XCircle onClick={closeToast} />;
}

function ToastContainer() {
  return (
    <Container
      position="bottom-left"
      autoClose={2000}
      hideProgressBar
      closeOnClick
      toastClassName={styles.toast}
      transition={Slide}
      closeButton={<CloseToast />}
    />
  );
}

export default ToastContainer;
