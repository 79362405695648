import React, { useState, useEffect } from 'react';
import { setConfiguration } from 'react-grid-system';
import Routes from './Routes';
import { FullPageSpinner } from './components/Spinner';
import Auth from './services/auth';
import history from './services/history';

setConfiguration({ gridColumns: 6 });

function Bootstrap() {
  const [isAppReady, setAppReady] = useState(false);
  const [user, setUser] = useState(Auth.buildUserStruct());

  function authenticationCallback(authUser) {
    setUser(authUser);
    setAppReady(true);
  }

  useEffect(() => {
    Auth.configureAwsAmplifyAndRegisterCallback(authenticationCallback);
    return () => {
      Auth.unregisterAwsAmplify();
    };
  }, []); // runs once
  if (isAppReady) return <Routes user={user} history={history} />;
  return <FullPageSpinner />;
}

export default Bootstrap;
