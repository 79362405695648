import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactCreatableSelect from 'react-select/creatable';
import theme from '../theme.scss';

const reactSelectStyles = {
  placeholder: (styles) => ({
    ...styles,
    color: theme.colorText
  }),
  input: (styles) => ({
    ...styles,
    color: theme.colorText,
    fontSize: 14
  }),
  control: (styles) => ({
    ...styles,
    marginTop: 8,
    boxShadow: 'none',
    borderRadius: theme.borderRadiusControls,
    '&:hover': {
      boxShadow: 'none',
      borderRadius: theme.borderRadiusControls
    }
  }),
  option: (styles) => ({
    ...styles,
    color: theme.colorText
  })
};

function convertOptions(options) {
  return options.map((opt) => {
    if (typeof opt === 'object') return opt;

    return { value: opt, label: opt };
  });
}

export default function MultiValueEditor({ fetchOptions, onChange, options, values }) {
  const selected = values ? values.map((value) => ({ value, label: value })) : null;

  const [currentOptions, setOptions] = useState(convertOptions(options));

  function fetchAndUpdateOptions() {
    let isMounted = true;
    async function updateOptions() {
      if (fetchOptions) {
        const fetchedOptions = await fetchOptions();

        // need to guard against being unmounted before the async call completed
        if (isMounted) setOptions(convertOptions(fetchedOptions));
      }
    }
    updateOptions();
    return () => {
      isMounted = false;
    };
  }

  useEffect(fetchAndUpdateOptions, [fetchOptions]);

  function onSelectionChange(selection) {
    onChange(selection ? selection.map((s) => s.value) : []);
  }

  return (
    <ReactCreatableSelect
      options={currentOptions}
      isMulti
      onChange={onSelectionChange}
      styles={reactSelectStyles}
      defaultValue={selected}
      isClearable
    />
  );
}

MultiValueEditor.defaultProps = {
  options: [],
  values: [],
  fetchOptions: null
};

MultiValueEditor.propTypes = {
  fetchOptions: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.any),
  values: PropTypes.arrayOf(PropTypes.any)
};
