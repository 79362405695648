import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';
import { MorphingRevealIconButton } from './Button';

function MenuItem({ ItemType, item, closeHandler }) {
  function onClick() {
    item.handler();
    closeHandler();
  }
  return (
    <ItemType value="" onClick={onClick}>
      {item.label}
    </ItemType>
  );
}

export function MenuItems({ items, ItemType, closeHandler }) {
  return items.map(item => <MenuItem key={item.label} ItemType={ItemType} item={item} closeHandler={closeHandler} />);
}

function DropDownMenu({ Icon, children, isOpen, setMenuState, openUp }) {
  const menuRef = useRef(null);
  const closeMenu = event => {
    // need to check both parent and target node, as SVG might be source and might not trigger
    if (event && menuRef && (menuRef.current.contains(event.path[0]) || menuRef.current.contains(event.path[1]))) {
      return;
    }
    if (isOpen) setMenuState(false);
  };

  useEffect(() => {
    document.addEventListener('click', closeMenu);
    return () => {
      document.removeEventListener('click', closeMenu);
    };
  });

  return (
    <div className={classnames('dropdown', 'is-right', { 'is-active': isOpen }, { 'is-up': openUp })} ref={menuRef}>
      <div className="dropdown-trigger">
        <MorphingRevealIconButton isOpen={isOpen} onClick={() => setMenuState(!isOpen)} Icon={Icon} />
      </div>
      <div className="dropdown-menu" role="menu">
        <div className="dropdown-content">{children}</div>
      </div>
    </div>
  );
}

export default DropDownMenu;
