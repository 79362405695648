import React, { useState } from 'react';
import copy from 'copy-to-clipboard';
import classnames from 'classnames';
import { Col, Row } from 'react-grid-system';
import { Copy } from 'react-feather';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { MainSection } from './Containers';
import { Button } from './Button';
import UserAvatar from './UserAvatar';
import AppContainer from './AppContainer';
import styles from './styles/ProfileScene.module.scss';
import config, { webhookUrlForUser, tagsUrlForUser } from '../services/config';
import apiClient from '../services/api-client';
import Spinner from './Spinner';

const avatarFrameStyle = {
  marginTop: 3,
  marginLeft: 3
};

function ProfileScene({ user }) {
  const [isFetching, setFetching] = useState(false);
  const webhookUrl = webhookUrlForUser(user);
  const tagsUrl = tagsUrlForUser(user);

  async function exportBookmarks() {
    try {
      setFetching(true);
      const bookmarks = await apiClient.fetchBookmarksForExport(user.credentials, config);

      const json = JSON.stringify(bookmarks, null, 2);
      const blob = new Blob([json], { type: 'text/plain;charset=utf-8' });

      const now = moment().format('MMDDYY');
      saveAs(blob, `bookmarks-${now}.json`);
      console.log('exported', `bookmarks-${now}.json`);
    } catch (ex) {
      console.error(ex);
    }
    setFetching(false);
  }

  return (
    <AppContainer user={user}>
      <MainSection>
        <div className={styles.card}>
          <Row className={styles.row}>
            <Col xs={6} md={2}>
              <div className={styles.avatarBorder}>
                <UserAvatar user={user} round style={avatarFrameStyle} size={150} />
              </div>
            </Col>
            <Col xs={6} md={4}>
              <h2 className={classnames(styles.noMarginBottom, styles.name)}>
                {`${user.attributes.given_name} ${user.attributes.family_name}`}
              </h2>
              <p className={classnames(styles.attributeValue, styles.name)}>{user.attributes.email}</p>
              <div className={styles.attributes}>
                <h4 className={styles.noMarginBottom}>Webhook</h4>
                <div className={classnames(styles.webhook)}>
                  {webhookUrl}
                  <Button onClick={() => copy(webhookUrl)}>
                    <Copy />
                  </Button>
                </div>
                <h4 className={styles.noMarginBottom}>Tags</h4>
                <div className={classnames(styles.webhook)}>
                  {tagsUrl}
                  <Button onClick={() => copy(tagsUrl)}>
                    <Copy />
                  </Button>
                </div>
                <Button onClick={exportBookmarks} outlined rounded={false} color={null} className={styles.buttonFrame}>
                  <div className={styles.buttonFrame}>Export</div>
                </Button>
                {isFetching && <Spinner style={{ marginLeft: 8 }} />}
              </div>
            </Col>
          </Row>
        </div>
      </MainSection>
    </AppContainer>
  );
}

export default ProfileScene;
